// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.aside {
  position: fixed;
  top: 35%;
  width: 100px;
  left: 1%;

  @media (min-width: 1100px) {
    left: 10%;
  }

  &:empty {
    display: none;
  }
}
`, "",{"version":3,"sources":["webpack://./styles/Aside.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,QAAQ;EACR,YAAY;EACZ,QAAQ;;EAER;IACE,SAAS;EACX;;EAEA;IACE,aAAa;EACf;AACF","sourcesContent":[".aside {\n  position: fixed;\n  top: 35%;\n  width: 100px;\n  left: 1%;\n\n  @media (min-width: 1100px) {\n    left: 10%;\n  }\n\n  &:empty {\n    display: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
